import { get } from 'lodash';

import legal from '~/localization/legal.json';

/**
 * Get a legal text from Cosmic (only from Legal - All)
 *
 * @param locale a locale code (e.g. en-US)
 * @param legalText one of LegalText values. Not typed here to provide better DX (allows to dynamically construct text keys).
 *
 * @returns string if there is a requested text or null
 */
export function getLegalAllText(
  locale: string,
  legalText: string
): string | null {
  return get(legal, `${locale}.legal-all.${legalText}`, null);
}
