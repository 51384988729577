/**
 * A registry of text keys defined in `legal` objects in Cosmic.
 * Some of them may be absent for certain states.
 */
export const enum LegalText {
  SUBSCRIPTION_TAX = 'subscription_tax',
  DEFINITION_6_MONTH = 'definition_6_month',
  DEFINITION_12_MONTH = 'definition_12_month',
  EARLY_TERMINATION_FEE = 'early_termination_fee',
  DEFINITION_LEASE = 'definition_lease',
  SUBMITTED_INFORMATION_WITH_PRICE_CHART = 'submitted_information_with_price_chart',
  SUBMITTED_INFORMATION_WITH_PRICE_CHART_ABS = 'submitted_information_with_price_chart_abs',
  STRIPE_STORE_SUBMITTED_INFORMATION = 'stripe_store_submitted_information',
  EV_TAX_EXEMPTION = 'ev_tax_exemption',
  CREDIT_CARD_PERMISSION = 'credit_card_permission',
  CREDIT_BACKGROUND_CHECK = 'credit_background_check',
  VALID_DRIVERS_LICENSE_AGREEMENT = 'valid_drivers_license_agreement',
  INSURANCE_POLICY_CHECKOUT = 'insurance_policy_checkout',
  INSURANCE_POLICY_LINK_TEXT = 'insurance_policy_link_text',
  INSURANCE_POLICY_LINK_HREF = 'insurance_policy_link_href',
  TERMS_AND_CONDITIONS_CHECKBOX = 'terms_and_conditions_checkbox',
  JOBAUTO_WHITEPAPER_LEAD_FORM_GPDR_CHECKBOX = 'gdpr_jobauto_whitepaper_lead_form_checkbox',
  JOBAUTO_WHITEPAPER_LEAD_FORM_GPDR_DETAILS = 'gdpr_jobauto_whitepaper_lead_form_details',
  GDPR_GENERIC_CONTACT_FORM_CHECKBOX = 'gdpr_generic_consent_checkbox',
  GDPR_GENERIC_CONTACT_FORM_DETAILS = 'gdpr_generic_consent_details',
  NEWSLETTER_CHECKBOX_TEXT = 'newsletter_checkbox_text',
  INSURANCE_INFO = 'insurance_info',
}
