import { Button } from '@finn/design-system/atoms/button';
import { ModalIcons } from '@finn/ui-components';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  localeId: string;
  onClose(): void;
};

const NotifySuccess: React.FC<Props> = ({ localeId, onClose }) => {
  const i18n = useIntl();

  const successTitle = i18n.formatMessage({ id: `${localeId}.successTitle` });
  const successDescription = i18n.formatMessage({
    id: `${localeId}.successDescription`,
  });
  const successCtaLabel = i18n.formatMessage({
    id: `${localeId}.successCtaLabel`,
  });

  return (
    <div className="px-6 py-12">
      <ModalIcons.Success className="mx-1 mb-8 mt-1" />
      <h1 className="mobile-t1-semibold md:web-t1-semibold">{successTitle}</h1>
      <p className="body-16-light mb-8 mt-4">{successDescription}</p>
      <div className="mx-auto max-w-52">
        <Button className="w-full" type="button" onClick={onClose}>
          {successCtaLabel}
        </Button>
      </div>
    </div>
  );
};

export default memo(NotifySuccess);
